body {
    background: #171717 url(images/body_bg_pc.jpg) fixed top no-repeat;
    float: none;
    background-size: 100%;
    margin: auto;
    font-family: 'Tahoma', Verdana, Segoe, sans-serif;
    color: #f4f4f2;
    font-size: 14px;
    text-shadow: 0 1px #000
}

a, a:link, a:visited, a:active, input.button {
    color: #eecb52;
    text-shadow: 0 1px #000
}

a:hover {
    text-decoration: none;
    color: #dd8b2a
}

ul {
    padding-left: 20px
}

a img {
    border: none
}

a.pranesimas {
    text-decoration: none;
    color: #fff
}

a.pranesimas img {
    vertical-align: top
}

a.pranesimas span {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 2px 6px;
    font-size: 12px
}

a.pranesimas span#in {
    background: #214822
}

a.pranesimas span#at {
    background: #482121
}

ul.choose_wrapper {
    padding: 0;
    margin: 0;
    padding-top: 15px;
    height: 40px
}

.block_button {
    background: rgba(2, 2, 2, 0.5);
    font-size: 10px;
    font-weight: none;
    display: inline-block;
    padding: 5px;
    -webkit-border-radius: 5px;
    -webkit-box-shadow: inset 0 4px 2px 0 rgba(236, 236, 236, 0.2);
    -moz-box-shadow: inset 0 4px 2px 0 rgba(236, 236, 236, 0.2);
    box-shadow: inset 0 4px 2px 0 rgba(236, 236, 236, 0.2);
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #2d2c2c;
    text-align: center;
    min-width: 90px;
    text-decoration: none;
    margin-bottom: 3px
}

.block_button img#big {
    display: block;
    padding-bottom: 3px;
    width: 55px;
    margin: 0 auto
}

.resources_wrapper {
    height: 36px
}

.resources_wrapper .warehouse {
    display: block;
    text-align: right;
    color: #dfdddd;
    font-size: 10px;
    padding-right: 5px
}

.resources_wrapper #status-bar {
    line-height: 19px;
    font-size: 12px;
    font-weight: 600;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    border: 1px #272727 solid;
    padding-left: 2px;
    background: rgba(23, 23, 23, 0.5);
    -webkit-box-shadow: inset 0 4px 2px 0 rgba(236, 236, 236, 0.2);
    -moz-box-shadow: inset 0 4px 2px 0 rgba(236, 236, 236, 0.2);
    box-shadow: inset 0 4px 2px 0 rgba(236, 236, 236, 0.2)
}

#suvartojimas {
    height: 0 auto;
    padding: 8px 3px 0;
    font-size: 10px;
    color: #dfdddd;
    line-height: 20px
}

#suvartojimas span {
    padding-right: 5px
}

#suvartojimas .small_button {
    display: inline;
    padding: 2px 6px;
    white-space: nowrap
}

.choose_wrapper li {
    list-style-type: none;
    margin: 0;
    padding: 0 2px;
    display: inline
}

.choose_wrapper li a {
    padding: 4px 8px;
    background: #282828;
    text-decoration: none;
    color: #fff;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: inset 0 4px 4px 0 rgba(75, 74, 74, 1);
    -moz-box-shadow: inset 0 4px 4px 0 rgba(75, 74, 74, 1);
    box-shadow: inset 0 4px 4px 0 rgba(75, 74, 74, 1);
    border: 1px #282828 solid;
    border-bottom: none;
    white-space: nowrap
}

.choose_wrapper li a#active {
    color: #000;
    background: #e1c635;
    text-shadow: 0 1px #d4b825;
    -webkit-box-shadow: inset 0 4px 4px 0 rgba(249, 239, 188, 1);
    -moz-box-shadow: inset 0 4px 4px 0 rgba(249, 239, 188, 1);
    box-shadow: inset 0 4px 4px 0 rgba(249, 239, 188, 1);
    padding: 6px 8px
}

.choose_wrapper li a:hover {
    padding: 6px 8px
}

.chose_body_wrapper {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 0 1px 8px;
    background-color: rgba(72, 72, 72, 0.2);
    border: 1px solid rgba(172, 172, 172, 0.1);
    margin: -8px 2px 9px;
    box-shadow: 0 0 4px 0 #282727
}

.chose_body_buttons_wrapper {
    background: #383737;
    display: inline-block;
    padding: 4px;
    height: auto;
    border: 1px solid #444;
    border-top: none;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px
}

.chose_body_wrapper .title_text {
    display: inline-block;
    margin: 14px 0
}

.stat_block div {
    display: inline-block
}

.stat_block {
    display: block;
    margin-bottom: 3px;
    background: rgba(31, 31, 31, 0.7);
    border: 1px solid #252424;
    min-height: 29px;
    line-height: 26px;
    text-align: left;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: inset 0 3px 3px 0 rgba(75, 74, 74, 1);
    -moz-box-shadow: inset 0 3px 3px 0 rgba(75, 74, 74, 1);
    box-shadow: inset 0 3px 3px 0 rgba(75, 74, 74, 1);
    cursor: pointer
}

.stat_block:hover {
    border-bottom: 2px #a8973b solid;
    margin-bottom: 2px
}

.stat_block#active {
    border-bottom: 2px red solid;
    margin-bottom: 1px
}

.stat_vt {
    border: 1px solid #383838;
    float: left;
    margin: 7px 2px 6px 3px;
    padding: 4px 3px 3px;
    line-height: 12px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #fff;
    background: #1f1e1e;
    text-shadow: none;
    font-size: 10px
}

.stat_vt#gold {
    background: #edbf3a;
    border: 1px solid #fcdd84;
    color: #000;
    font-weight: 700
}

.stat_vt#silver {
    background: #ebeaea;
    border: 1px solid #ececea;
    color: #000;
    font-weight: 700
}

.stat_vt#bronse {
    background: #d28415;
    border: 1px solid #fab655;
    color: #000;
    font-weight: 700
}

a.stat_nick {
    padding: 3px 1px 2px;
    color: #fff;
    font-weight: 700;
    display: inline-block;
    text-decoration: none
}

.stat_aly {
    color: #b5b4b4;
    font-size: 10px;
    height: 20px
}

.stat_aly img {
    padding-right: 0;
    width: 15px
}

.stat_stats {
    background: #3b3a3a;
    border: 1px solid #2e2d2d;
    float: right;
    margin-top: 6px;
    margin-right: 4px;
    padding: 0 3px;
    height: 19px;
    line-height: 18px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #b5b4b4;
    text-shadow: none;
    font-size: 10px
}

.stat_stats span {
    margin-top: 3px;
    line-height: 15px
}

.stat_stats img {
    vertical-align: middle;
    padding-bottom: 2px
}

.rating_pos {
    color: #585757;
    font-size: 10px
}

.rating_pos span {
    font-size: 8px
}

.rating_pos#up {
    color: #39b717
}

.rating_pos#down {
    color: #d61d1d
}

@media only screen and (max-width: 320px) {
    .choose_wrapper li {
        display: block;
        height: 35px
    }

    ul.choose_wrapper {
        height: auto
    }

    a.stat_nick {
        font-size: 12px
    }
}

.big_logo {
    background: #111;
    line-height: 5px;
    background: url(images/logo_bg.gif) repeat-x;
    position: relative
}

.big_logo img {
    max-width: 100%
}

.big_text {
    background: #111;
    color: #fff;
    padding-bottom: 3px;
    text-align: center
}

.wrapper {
    max-width: 750px;
    background: #d6d385;
    margin: auto;
    padding: 1px;
    border: #ac8b2f solid 1px;
    -webkit-box-shadow: 0 5px 4px -4px #000;
    -moz-box-shadow: 0 5px 4px -4px #000;
    box-shadow: 0 5px 4px -4px #000;
    border-radius: 4px
}

.shbox {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 2px;
    margin: 0 2px 2px
}

.pranesejas td:first-child {
    text-align: right;
    vertical-align: top
}

.pranesejas td:last-child {
    vertical-align: top;
    width: 100%
}

.pranesejas span#first {
    width: 98%;
    padding: 6px 4px;
    color: #fff;
    border: 1px solid #ffc93b;
    position: relative;
    display: inline-block;
    background-color: rgba(25, 25, 25, 0.6)
}

.pranesejas h2 {
    margin: 0 0 3px;
    color: #ffc93b
}

.pranesejas span:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent #ffc93b;
    display: block;
    left: -6px;
    top: 10px
}

.judejimas {
    padding: 4px 2px 5px 4px;
    line-height: 90%;
    text-align: left;
    font-weight: 700;
    display: block;
    text-decoration: none;
    color: #aec3d0
}

.judejimas#as_puolu {
    background-color: rgba(40, 58, 70, 0.6);
    text-align: left;
    border-top: solid 1px #304653;
    color: #c2d8e6
}

.judejimas#zvalgyba {
    background-color: rgba(40, 58, 70, 0.6);
    text-align: left;
    border-top: solid 1px #304653;
    color: #aec3d0
}

.judejimas#mane_puola {
    background-color: rgba(126, 32, 32, 0.6);
    text-align: left;
    border-top: solid 1px #892323;
    color: #e9b5b5;
    font-weight: 700
}

.judejimas#pastiprinimai_grizta, .judejimas#pastiprinimai_eina {
    background-color: rgba(17, 32, 17, 0.6);
    text-align: left;
    border-top: solid 1px #324032;
    color: #c2c2c2
}

#green.shbox, #green.button, #green.simple_button {
    border-left: #9fa641 solid 1px;
    border-right: #9fa641 solid 1px;
    border-bottom: #9fa641 solid 1px;
    background: #d3e596
}

#red.shbox, #red.button, #red.simple_button {
    border-left: #c65c61 solid 1px;
    border-right: #c65c61 solid 1px;
    border-bottom: #c65c61 solid 1px;
    background: #eec5c2;
    color: #000
}

#black.shbox, #black.button, #black.simple_button {
    border-left: #2d2b2b solid 1px;
    border-right: #2d2b2b solid 1px;
    border-bottom: #2d2b2b solid 1px;
    background: #dcdcd9;
    color: #000
}

input[type=input], input[type=text], input[type=password], input[type=file], select, textarea {
    box-shadow: inset 0 0 3px #999b94;
    border: #171717 solid 1px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px 0;
    margin: 4px 0;
    text-align: center
}

select {
    height: 30px
}

option {
    padding: 4px 2px;
    border-bottom: 1px #c2bfbf dashed
}

input[type=text]:hover, input[type=password]:hover, input[type=file], textarea:hover, select:hover {
    border: #eecb52 solid 1px
}

a.nice_button, .nice_button {
    background: url(images/red_button_bg.gif) repeat-x;
    font-size: 14px;
    text-align: center;
    position: relative;
    display: block;
    height: 35px;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
    width: 70%;
    margin: 6px auto;
    color: #fcf5f5;
    border: none
}

.nice_button:before {
    content: "";
    background: url(images/red_button_left.gif) no-repeat;
    display: inline-block;
    position: absolute;
    left: 0;
    height: 35px;
    top: 0;
    width: 5px
}

.nice_button:after {
    content: "";
    background: url(images/red_button_right.gif) no-repeat;
    display: inline-block;
    position: absolute;
    right: 0;
    height: 35px;
    top: 0;
    width: 5px
}

.nice_button span {
    position: relative;
    top: 7px
}

.nice_button#green {
    content: "";
    background: url(images/green_button_bg.gif) repeat-x
}

.nice_button#green:before {
    content: "";
    background: url(images/green_button_left.gif) no-repeat;
    display: inline-block;
    position: absolute;
    left: 0;
    height: 50px;
    width: 5px
}

.nice_button#green:after {
    content: "";
    background: url(images/green_button_right.gif) no-repeat
}

input.button {
    width: 99%;
    margin: 3px;
    height: 32px
}

.message {
    padding: 3px 0 3px 4px;
    margin: 4px
}

.message#good {
    background: #587d4e
}

.message#bad {
    background: #a63c38
}

.message#warning {
    background: #767373
}

ul.errors_box {
    background: #a63c38;
    margin: 0 auto;
    padding: 3px 0 3px 15px
}

.logo {
    vertical-align: middle
}

.clock {
    font-size: 12px;
    text-align: left;
    padding: 1px
}

.top, .middle, .bottom, .pranesejas span#first {
    border: #ac8b2f solid 1px;
    -moz-box-shadow: inset 1px 1px 2px #3a3a3a;
    -webkit-box-shadow: inset 1px 1px 2px #3a3a3a;
    box-shadow: inset 1px 1px 2px #3a3a3a;
    text-align: center
}

.top {
    background: #323130 url(images/header_bg.gif?4) repeat-x
}

.middle {
    background: #333232 url(images/smartpc2.gif) no-repeat top;
    background-size: 750px auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    margin-top: 1px;
    padding: 4px 1px 6px
}

.bottom {
    margin-top: 1px;
    padding: 2px 1px 0;
    text-align: center;
    background: #1e1d1c;
    color: #6e8815
}

.line {
    margin: 4px 0;
    width: 100%;
    height: 1px;
    background: #494646;
    border-top: #252222 solid 1px
}

.kalbos {
    padding: 5px 0 2px
}

.kalbos a {
    padding: 4px
}

.kalbos img {
    border: solid 1px #ac8b2f
}

.kalbos img:hover {
    border: solid 1px #d6d385
}

.button {
    font-weight: 700;
    display: block;
    padding: 3px;
    margin: 0 -1px 3px;
    text-decoration: none;
    text-align: center;
    text-shadow: 0 1px #000;
    -moz-box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.1);
    -webkit-box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.1);
    box-shadow: inset 1px 3px 2px rgba(255, 255, 255, 0.1)
}

a.button#green2 {
    background: #607e20;
    color: #fff
}

a.small_button {
    color: #000;
    text-decoration: none;
    font-size: 12px;
    margin-bottom: 1px;
    margin-top: 1px;
    text-shadow: 0 1px #dec473
}

.small_button {
    display: block;
    background: #dec473;
    -moz-box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.2);
    box-shadow: inset 1px 3px 2px rgba(255, 255, 255, 0.2);
    border-bottom: #242323 solid 1px;
    padding: 0;
    width: 100%;
    margin-top: -3px
}

.small_button:hover {
    color: #a63c38;
    border-bottom: #eb2018 solid 1px
}

.small_button.link {
    margin-top: 5px;
    text-align: center;
    max-width: 150px;
    margin-top: 5px;
    text-align: center;
    max-width: 150px
}

.gyv_notices {
    display: inline-block;
    font-size: 12px;
    text-align: center;
    padding: 3px 0
}

.gyv_notices .small_button {
    width: 80px;
    color: #fff;
    padding: 3px 0;
    text-shadow: none
}

#menu.button {
    background: #2b2a2a url(images/menu_arrow.gif) no-repeat;
    background-position: right center;
    height: auto;
    text-align: left;
    border: #1a1a1a solid 1px;
    border-top: solid 1px #494646
}

#menu.button:hover {
    background-position: right center;
    line-height: 20px
}

#green.button, #green.simple_button {
    background-color: rgba(23, 23, 23, 0.6);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#444343', endColorstr='#272727');
    border: #171717 solid 1px;
    border-top: none
}

#red.button, #red.simple_button {
    color: #000;
    background: #e18488 url(images/perej.png) repeat-x top;
    border-top: #ed9296 solid 1px
}

#black.button, #black.simple_button {
    color: #FFF;
    background: #2d2b2b url(images/perej.png) repeat-x top;
    border-top: #393838 solid 1px
}

#green2.simple_button {
    color: #000;
    background: #a9ca67 url(images/perej.png) repeat-x top;
    border-top: #bbc168 solid 1px
}

.button:hover, .button:active, .simple_button:hover, .simple_button:hover, .simple_button:active {
    border: #ece99f solid 1px
}

a.button:active, .simple_button:active, input.button:active, .simple_button:active, #green.button:hover {
    color: #dd8b2a;
    box-shadow: 0 0 2px 0 #7f8178
}

.pranesejas span, .wrapper, .top, .middle, .bottom, a.button, .simple_button, .small_button, input[type=submit], input[type=input], input[type=text], input[type=password], input[type=file], select, textarea {
    border-radius: 5px;
    -webkit-appearance: none;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    -icab-border-radius: 5px
}

table.box {
    color: #333;
    width: 100%;
    margin: 5px 0;
    border-spacing: 0
}

.blokas_top {
    border-top: #ac8b2f solid 1px;
    -moz-border-radius-topright: 8px;
    -moz-border-radius-topleft: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px
}

.blokas {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 7px 1px;
    background-color: rgba(72, 72, 72, 0.2);
    border: 1px solid rgba(172, 172, 172, 0.1);
    margin: 2px 2px 20px;
    box-shadow: 0 0 4px 0 #282727
}

h2 {
    font-size: 14px;
    margin: 10px 0 6px;
    padding: 0
}

a.s_button {
    display: block;
    -moz-border-radius: 6px;
    border-radius: 6px;
    height: 20px;
    text-align: center;
    text-decoration: none;
    border: #282828 solid 1px;
    box-shadow: 0 0 2px 0 #a0a58d;
    padding-top: 4px;
    margin: 0;
    width: 25px;
    height: 21px;
    background: #282828;
    -moz-box-shadow: inset 1px 1px 2px #4d4c4c;
    -webkit-box-shadow: inset 1px 1px 2px #4d4c4c;
    box-shadow: inset 1px 3px 2px #4d4c4c
}

a.s_button:hover {
    border: #ece99f solid 1px;
    font-weight: 400;
    box-shadow: 0 0 2px 0 #7f8178
}

#center {
    text-align: center
}

#l_color_1 {
    background-color: rgba(55, 55, 55, 0.6)
}

#l_color_2 {
    background-color: rgba(42, 42, 42, 0.6)
}

table.navigacija {
    width: 100%
}

table.navigacija td {
    width: 50%;
    text-align: center;
    height: 14px;
    vertical-align: bottom
}

p, fieldset {
    margin: 0;
    padding: 0;
    border: 0 solid #FFF
}

#sub_line {
    background: url(images/th_bg.gif) repeat-x;
    background-position: 0 12px;
    height: 16px;
    width: 50%;
    opacity: 0
}

.title_text {
    color: #ffcf48;
    font-size: 14px;
    text-shadow: 0 1px #000;
    padding: 5px 25px;
    background: #000;
    border: solid 1px #494646;
    border-top: solid 1px #252222;
    border-left: solid 1px #252222;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    white-space: nowrap;
    text-align: center;
    background-color: rgba(27, 27, 27, 0.6)
}

.title_text span {
    opacity: 1
}

.title_text img {
    padding-left: 3px
}

a.page {
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: #282828;
    padding: 2px 6px;
    text-align: center;
    text-decoration: none;
    border: #282828 solid 1px;
    margin: 4px 2px;
    font-size: 12px;
    -moz-box-shadow: inset 1px 1px 2px #4d4c4c;
    -webkit-box-shadow: inset 1px 1px 2px #4d4c4c;
    box-shadow: inset 1px 3px 2px #4d4c4c
}

a.page:hover {
    font-weight: 400
}

#pushed {
    background: #282727;
    font-size: 12px;
    border: solid 1px #494646;
    border-top: solid 1px #252222;
    border-left: solid 1px #252222;
    box-shadow: inset 0 0 0 #0b0b0b
}

table.stats {
    width: 100%;
    text-align: center;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background-color: rgba(51, 46, 35, 0.6)
}

table.stats tr, table.stats td {
    border: 1px solid #1c1b1b;
    vertical-align: middle;
    padding: 2px 1px
}

table.stats img {
    vertical-align: top
}

table.stats #footer, table.stats #header {
    background-color: rgba(42, 42, 42, 0.6);
    text-align: center;
    font-size: 12px
}

.ats {
    padding: 5px;
    margin: 12px 0;
    text-align: center;
    -moz-border-radius: 5px;
    border-radius: 5px
}

.ats#puolantis {
    background: #861414
}

.ats#ginantis {
    background: #355c31
}

.notice {
    max-width: 200px;
    padding: 3px;
    color: #d6d385;
    text-shadow: 0 1px #000;
    font-weight: 700;
    margin-left: -2px;
    border: 1px solid #ac8b2f
}

.notice#green {
    background: #244a23 url(images/close.gif) no-repeat;
    background-position: right center
}

.notice#red {
    background: #ad0000
}

.stat_search_wrapper {
    padding-top: 8px;
    text-align: left
}

.stat_search_wrapper input[type="input"] {
    padding: 2px;
    height: 15px
}

.stat_search_wrapper input[type="submit"] {
    font-size: 12px;
    height: 24px
}